/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect } from "react";

import CreditApi from "../../../apis/credit-api";

const formatCurrency = (valor) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
};


const CreditIndex = () => {
  const [credits, setCredits] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const billingClient = new CreditApi();

  const fetchData = async ({ page, sizePerPage, filter }) => {
    const query = filter ? { query: filter || undefined } : {};
    const response = await billingClient.getAll(page, sizePerPage, query);
    setTotalSize(response.total);
    setCredits(response.data);
  };


  useEffect(() => {
    fetchData({ page: 0, sizePerPage: 12 });
  }, []);

  return (
    <>
      <Row>
        <Col sm={12}>
          <h1><i className="fa-solid fa-coins"></i> Créditos</h1>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Card className="shadow">
            <CardBody>
              <table className={"table default-table"}>
                <thead>
                  <tr>
                    <th>Crédito</th>
                    <th width={'100px'}>Total</th>
                    <th width={'100px'}>Usado</th>
                    <th width={'100px'}>Restante</th>
                  </tr>
                </thead>

                <tbody>
                  {credits.map((credit, index) => (
                    <tr key={index}>
                      <td>{credit.name}</td>
                      <td>{formatCurrency(credit.amount)}</td>
                      <td>{formatCurrency(credit.amount_used)}</td>
                      <td>{formatCurrency(credit.amount_remaining)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default CreditIndex;
